+ function (window, document,$, undefined) {
    "use strict";

    var Grid = function (el) {
        this.element = $(el);
        this.options = this.element.data('js-grid') || {};
        this.defaults = {
            'id': 'grid',
            'row': '.row',
            'container': '.container'
        };
    };

    Grid.prototype.constructor = Grid;

    Grid.prototype.appendGrid = function () {
        var self = this;
        var $g = $('<div />', {
            'id': self.options.id,
            'css': {
                'background-size': '10px 10px',
                'background-image': 'linear-gradient(to right, grey 1px, transparent 1px), linear-gradient(to bottom, grey 1px, transparent 1px)',
                'position': 'fixed',
                'top': '0',
                'z-index': '100000',
                'left': '0',
                'width': '100vw',
                'height': '100vh',
                'opacity': '.1',
                'display': 'none'
            }
        });
        this.element.append($g);
    };

    Grid.prototype.setEvents = function () {
        var self = this;
        var $g = $('#' + self.options.id);
        var $rows = $(self.options.row);
        var $containers = $(self.options.container);

        document.onkeypress = function (oKeyEvent) {
            if (oKeyEvent.charCode === 729) { //alt+rightShift+g key
                $g.toggle();
            }

            if (oKeyEvent.charCode === 216) { //alt+rightShift+ key
                $rows.each(function () {
                    this.style.outline = this.style.outline ? '' : '1px solid red';
                });
            }

            if (oKeyEvent.charCode === 169) { //alt+rightShift+c key
                $containers.each(function () {
                    this.style.outline = this.style.outline ? '' : '1px solid green';
                });
            }

            return true;
        };

    };

    Grid.prototype.init = function () {
        $.extend(this.options, this.defaults);
        this.appendGrid();
        this.setEvents();
    };

    // PLUGIN DEFINITION
    // ============================
    $.fn.grid = function () {
        return this.each(function () {
            var x = new Grid(this);
            x.init();
        });
    };

    // Run on load
    // ============================
    $(document).ready(function () {
        $('body[data-js-grid]').grid();
    });

}(window, document, window.jQuery);
