/*

https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
*/
+ function (window, document) {
    'use strict';
    var GoTo = function (element, options) {
        this.el = element || null;
        this.target = options;
        if (!this.el) {
            throw "Element not found";
        }
    };

    //Public
    GoTo.prototype.constructor = GoTo;

    GoTo.prototype._getOffset = function (el) {
        var bodyRect = document.documentElement.getBoundingClientRect() ||
            document.body.getBoundingClientRect(),
            elemRect = el.getBoundingClientRect();

        return elemRect.top - bodyRect.top
    };

    GoTo.prototype.smoothScroll = function () {
        var self = this;
        var currentScroll = self._getOffset(this.target);
        console.log(currentScroll, this.target);
        if (currentScroll > 0) {
            window.requestAnimationFrame(function () {
                self.smoothScroll();
            });
            window.scrollTo(0, currentScroll - (currentScroll / 5));
        }
    };

    GoTo.prototype.addEvents = function () {
        var self = this;
        this.el.onclick = function (ev) {
            ev.preventDefault();
            self.smoothScroll();
        }
    };

    /**
     * Initiate
     */
    GoTo.prototype.init = function () {
        //this.options = extend(this.defaults, this.options);
        this.target = document.querySelectorAll(this.target)[0] ||
            document.documentElement ||
            document.body;

        this.addEvents();
    };

    /////Auto Start
    var index = 0;
    var inputObjs = document.querySelectorAll('[data-js-goto]');
    for (index in inputObjs) {
        if (inputObjs.hasOwnProperty(index)) {
            var options = inputObjs[index].dataset.jsGoto || '';
            var obj = new GoTo(inputObjs[index], options);
            obj.init();
        }
    }

}(window, document);
