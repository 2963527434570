/*
 * Detect the End of CSS Animations and Transitions with JavaScript
 *
 * @method  whichTransitionEvent()
 * @returns String
 * @usage:
 *   var transitionEvent = whichTransitionEvent();
 *
 *   $(".button").click(function(){
 *      $(this).addClass("animate");
 *      $(this).one(transitionEvent, function(event) {
 *       // Do something when the transition ends
 *      });
 *   });
 */
Window.prototype.whichTransitionEvent = Window.prototype.whichTransitionEvent || function () {
    'use strict';
    var el = document.createElement('fakeelement');
    var transitions = {
        'transition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'MozTransition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd'
    };

    for (var t in transitions) {
        if (el.style[t] !== undefined) {
            return transitions[t];
        }
    }
};

/*
 * Detect when animations (keyframes) end
 *
 * @method  whichAnimationEvent()
 * @returns String
 * @usage:
 *   var animationEvent = whichAnimationEvent();
 *
 *   $(".button").click(function(){
 *      $(this).addClass("animate");
 *      $(this).one(animationEvent, function(event) {
 *       // Do something when the transition ends
 *      });
 *   });
 */
Window.prototype.whichAnimationEvent = Window.prototype.whichAnimationEvent || function () {
    'use strict';
    var el = document.createElement("fakeelement");
    var animations = {
        "animation": "animationend",
        "OAnimation": "oAnimationEnd",
        "MozAnimation": "animationend",
        "WebkitAnimation": "webkitAnimationEnd"
    };

    for (var t in animations) {
        if (el.style[t] !== undefined) {
            return animations[t];
        }
    }
}
