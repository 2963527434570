+ function (window, document) {
    'use strict';
    var Metadata = function () {
        this.head = document.head;
    };

    Metadata.prototype.constructor = Metadata;

    Metadata.prototype.select = function (str) {
        return this.head.querySelector("meta[property=" + JSON.stringify(str) + "]").content || null;
    };

    Metadata.prototype.insert = function (property, value) {
        var property = JSON.stringify(property) || null;
        var value = value || '';
        var meta;
        if (!property) { //
            throw "No property set";
        }

        meta = this.head.querySelector("meta[property=" + property + "]");
        if (!meta) {
            meta = document.createElement('meta');
            meta.property = property;
            this.head.appendChild(meta);
        }

        meta.content = JSON.stringify(value);
    };

    Metadata.prototype.remove = function (property) {
        var property = JSON.stringify(property) || null;
        var value = value || '';
        var meta;
        if (!property) { //
            throw "No property set";
        }

        meta = this.head.querySelector("meta[property=" + property + "]");
        if (!meta) {
            meta = document.createElement('meta');
            meta.property = property;
            this.head.appendChild(meta);
        }

        meta.content = JSON.stringify(value);
    };
}(window, document);
