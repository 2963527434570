/*
Merge the contents of two or more objects together into the first object.
*/
Map.prototype.extend = Map.prototype.extend || function () {
    'use strict';
    for (var i = 1; i < arguments.length; i++) {
        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key)) {
                if (typeof arguments[0][key] === 'object' &&
                    typeof arguments[i][key] === 'object') {
                    extend(arguments[0][key], arguments[i][key]);
                } else {
                    arguments[0][key] = arguments[i][key];
                }
            }
        }
    }

    return arguments[0];
};
