/**
 * Swaps image for alterative
 *
 * @usage  <img src="assets/img/wlogo.svg" width="128" data-svg-alternative="assets/img/wlogo.png" />
 */
+ function (window, document) {
    'use strict';
    var SvgSwap = function (element, options) {
        this.el = element || null;
        this.options = options|{};
        if (!this.el) {
            throw "Element not found";
        }
    };

    //Public
    SvgSwap.prototype.constructor = SvgSwap;

    SvgSwap.prototype.addEvents = function () {
        var self = this;
        this.el.onerror = function (ev) {
            this.onerror=null;
            this.src = self.options;
        }
    };

    /**
     * Initiate
     */
    SvgSwap.prototype.init = function () {
        this.addEvents();
    };

    /////Auto Start
    var index = 0;
    var inputObjs = document.querySelectorAll('img[data-svg-alternative]');
    for (index in inputObjs) {
        if (inputObjs.hasOwnProperty(index)) {
            var obj = new SvgSwap(inputObjs[index], inputObjs[index].getAttribute('data-svg-alternative'));
            obj.init();
        }
    }
}(window, document);
