+(function(window, document, $, undefined) {
    "use strict";

    $("[data-js-goto]").on({
        click: function(e) {
            var body = $("html, body");
            var target = $($(this).data("js-goto"));
            body.stop().animate(
                { scrollTop: target.offset().top },
                700,
                "swing",
                function() {}
            );
        }
    });

    $("[data-js-getPhysicians]").on({
        change: function(e) {
            var $self = $(this);
            $.get("api/physicians/" + $self.val(), function(response, status) {
                var $select = $("<select />", {
                    id: "physician",
                    class: "custom-select selectpicker",
                    name: "physician"
                }).append($("<option />"));

                for (var i = 0; i < response.data.length; i++) {
                    var item = response.data[i];
                    var $option = $("<option />", {
                        value: item.code
                    }).text(item.name);

                    if (i === 0 && response.data.length === 1) {
                        $option.attr("selected", "selected");
                    }

                    $select.append($option);
                }

                $("#select-specialist").html($select);
            });
        }
    });
})(window, document, window.jQuery);
