/*
    @url: https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
*/
+ function (window, document) {
    'use strict';
    var Browser = function () {};

    //Public
    Browser.prototype.constructor = Browser;
    Browser.prototype.isOpera = function () {
        return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    };

    // Firefox 1.0+
    Browser.prototype.isFirefox = function () {
        return typeof InstallTrigger !== 'undefined';
    };

    // At least Safari 3+: "[object HTMLElementConstructor]"
    Browser.prototype.isSafari = function () {
        return Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    };

    // Internet Explorer 6-11
    Browser.prototype.isIE = function () {
        return /*@cc_on!@*/ false || !!document.documentMode;
    };

    // Internet Explorer 6-11
    Browser.prototype.isEdge = function () {
        return !isIE && !!window.StyleMedia;
    };

    // Chrome 1+
    Browser.prototype.isChrome = function () {
        return !!window.chrome && !!window.chrome.webstore;
    };

    // Blink engine detection
    Browser.prototype.isBlink = function () {
        return (isChrome || isOpera) && !!window.CSS;
    };

    Browser.prototype.testBrowser = function () {
        if (this.isChrome()) {
            return "chrome";
        }
        if (this.isSafari()) {
            return "safari";
        }
        if (this.isIE()) {
            return "ie";
        }
        if (this.isFirefox()) {
            return "firefox";
        }
        if (this.isOpera()) {
            return "opera";
        }
        if (this.isEdge()) {
            return "edge";
        }
        if (this.isBlink()) {
            return "blink";
        }
        return false;
    };

    /**
     * Initiate
     */
    Browser.prototype.init = function () {
        var _class = this.testBrowser();
        var el = document.body;
        if (_class && el.className.indexOf(_class) === -1) {
            el.className += ' ' + _class;
            el.className = el.className.trim();
        }
    };

    /////Auto Start
    var x = new Browser();
    x.init();
}(window, document);
