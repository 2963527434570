/***
 * @package String
 * @description String Polyfills class
 *
 ***/

/***
 * @method ucfirst()
 * @returns String
 * @short capitalize the string.
 *
 * @example
 *   ' string to capitalize '.capitalize() -> 'String to capitalize'
 *
 ***/
String.prototype.ucfirst = String.prototype.ucfirst || function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

/***
 * @method trim()
 * @returns String
 * @short trims the string.
 *
 * @example
 *   ' string to trim '.trim() -> 'string to trim'
 *
 ***/
String.prototype.trim = String.prototype.trim || function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
};

/***
 * @method trimLeft()
 * @returns String
 * @short trims the string.
 *
 * @example
 *   ' string to trim '.trimLeft() -> 'string to trim '
 *
 ***/
String.prototype.trimLeft = String.prototype.trimLeft || function () {
    return this.replace(/^[\s\uFEFF\xA0]+/, '');
};

/***
 * @method trimRight()
 * @returns String
 * @short trims the string.
 *
 * @example
 *   ' string to trim '.trimRight() -> ' string to trim'
 *
 ***/
String.prototype.trimRight = String.prototype.trimRight || function () {
    return this.replace(/[\s\uFEFF\xA0]+$/, '');
};

/***
 * @method allTrim()
 * @returns String
 * @short trims the string.
 *
 * @example
 *   ' string to   trim '.allTrim() -> 'string to trim'
 *
 ***/
String.prototype.allTrim = String.prototype.allTrim || function () {
    'use strict';
    return this.trim().replace(/[\s\uFEFF\xA0]+/g, ' ');
};

/***
 * @method truncate(<num>, [append] = '…')
 * @returns String
 * @short Truncates the string to the <num> characters.
 *
 * @example
 *   'string to truncate'.truncate(8) -> 'string t…'
 *   'string to truncate'.truncate(11, '+') -> 'string to t+'
 *   'string'.truncate(11) -> 'string'
 *
 ***/
String.prototype.truncate = String.prototype.truncate || function (num, append) {
    'use strict';
    append = append || '…';
    num = num || this.length;
    //return this.replace();
    //t.replace(new RegExp("^(.{"+length+"}[^\s]*).*"), "$1");
    return (this.length > num ? this.substring(0, num) + append : this);
};

/***
 * @method str2bool()
 * @returns Boolean
 * @short converts a string to bool
 *
 * @example
 *   'string'.str2bool() -> true
 *   'false'.str2bool() -> false
 *
 ***/
String.prototype.str2bool = String.prototype.str2bool || function () {
    'use strict';
    var str = this.trim().toLowerCase();
    switch (str) {
        case 'false':
        case 'no':
        case '0':
        case '':
            return false;
        default:
            return true;
    }
};

/***
 * @method camelize()
 * @param String
 * @returns String
 * @short Converts a string to Camel case
 *
 * @example
 *   'string to camelize'.camelize() -> stringToCamelize
 *   'string-to-camelize'.camelize('-') -> stringToCamelize
 ***/
String.prototype.camelize = String.prototype.camelize || function (separator) {
    'use strict';
    var separator = separator || ' ';
    var str = this.trim();
    var camelizedStr = '';
    var parts = str.split(separator);

    for (var i = 0; i < parts.length; i++) {
        camelizedStr += (i === 0) ? parts[i] : parts[i].ucfirst();
    }

    return camelizedStr;
}
