/*
*/
+ function (window, document) {
    'use strict';
    var Video = function (element, options) {
        this.el = element || null;
        this.options = options || {};
        if (!this.el) {
            throw "Element not found";
        }
    };

    //Public
    Video.prototype.constructor = Video;

    Video.prototype._addSourceToVideo = function (element, src, type) {
        var source = document.createElement('source');
        source.src = src;
        source.type = type;
        element.appendChild(source);
    };

    Video.prototype._suportVideo = function () {
        return !!document.createElement('video').canPlayType;
    };

    Video.prototype._suportH264 = function () {
        var v = document.createElement("video");
        return v.canPlayType('video/mp4; codecs="avc1.42E01E, mp4a.40.2"');
    };

    Video.prototype._suportOGV = function () {
        var v = document.createElement("video");
        return v.canPlayType('video/ogg; codecs="theora, vorbis"');
    };

    Video.prototype._suportWEBM = function () {
        var v = document.createElement("video");
        return v.canPlayType('video/webm; codecs="vp8, vorbis"');
    };

    Video.prototype._createPlayer = function (obj) {
        if (!this._suportVideo())
            return false;

        var
            video = false,
            ogv = (typeof obj.ogv) ? obj.ogv : false,
            mp4 = (typeof obj.mp4) ? obj.mp4 : false,
            webm = (typeof obj.webm) ? obj.webm : false;

        video = document.createElement('video');
        video.controls = (typeof obj.controls) ? obj.controls : false;
        video.muted = (typeof obj.muted) ? obj.muted : false;
        video.loop = obj.loop || true;

        if (this._suportWEBM() && webm) {
            this._addSourceToVideo(video, webm, 'video/webm');
            return video;
        }

        if (this._suportOGV() && ogv) {
            this._addSourceToVideo(video, ogv, 'video/ogg');
            return video;
        }

        if (this._suportH264() && mp4) {
            this._addSourceToVideo(video, mp4, 'video/mp4');
            return video;
        }
    };

    Video.prototype._addPoster = function (src, fn) {
        var newimg = new Image();
        newimg.onload = fn;
        img.src = img;
        img.id = "poster";
    };

    /**
     * Initiate
     */
    Video.prototype.init = function () {
        if (!this.options)
            return;

        try {
            var html = this._createPlayer(this.options);
            this.el.insertBefore(html, this.el.firstChild);
            html.load();
            html.oncanplaythrough = function () {
                html.play();
            };
            this.el.className+=' hasvideo';
        } catch (e) {
            // do nothing;
        }
        this.addEvents();
    };

    /////Auto Start
    var index = 0;
    var inputObjs = document.querySelectorAll('[data-js-video]');
    for (index in inputObjs) {
        if (inputObjs.hasOwnProperty(index)) {
            var options = inputObjs[index].dataset.jsVideo || '{}';
            try {
                options=JSON.parse(options);
                var obj = new Video(inputObjs[index], options);
                obj.init();
            } catch (error) {

            }
        }
    }

}(window, document);
