/**
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
 */
Number.prototype.isInteger = Number.prototype.isInteger || function (value) {
    'use strict';
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};


/**
 *
 * @link   http://stackoverflow.com/a/18090458/3194168
 * @usage  (22/7).round(3); // 3.143
 */
Number.prototype.round = Number.prototype.round || function(value) {
    'use strict';
    value = value || 10;
    return parseFloat( this.toFixed(value) );
};
