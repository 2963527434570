/***
 * @method filter( fn )
 * @params  Function
 * @returns Array
 * @short The filter() method creates a new array with all elements that pass the test implemented by the provided function.
 * @url https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter
 *
 * @example
 *   function isBigEnough(value) {
 *      return value >= 10;
 *   }
 *   var filtered = [12, 5, 8, 130, 44].filter(isBigEnough);
 *   // filtered is [12, 130, 44]
 *
 ***/
Array.prototype.filter = Array.prototype.filter || function (fun /*, thisArg*/ ) {
    'use strict';
    if (this === void 0 || this === null) {
        throw new TypeError();
    }

    var t = Object(this);
    var len = t.length >>> 0;
    if (typeof fun !== 'function') {
        throw new TypeError();
    }

    var res = [];
    var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
    for (var i = 0; i < len; i++) {
        if (i in t) {
            var val = t[i];

            // NOTE: Technically this should Object.defineProperty at
            //       the next index, as push can be affected by
            //       properties on Object.prototype and Array.prototype.
            //       But that method's new, and collisions should be
            //       rare, so use the more-compatible alternative.
            if (fun.call(thisArg, val, i, t)) {
                res.push(val);
            }
        }
    }

    return res;
};
