+ function (window, document) {
    "use strict";
    /**
     * @todo develop a real template system that controlls each char in the string not permiting unwhanted chars
     */

    var Masked = function (element, options) {
        this.el = element;
        this.options = {};
        this.defaults = {
            template: '  /  /    ',
            rule: /\d/
        };
        try {
            this.options = JSON.parse(options);
        } catch (e) {
            console.error(e.name + ': ' + e.message);
            console.info('Using defaults');
        }
    };

    Masked.prototype.constructor = Masked;

    /**
     * Object Extending Functionality
     *
     * @link https://gist.github.com/jfonte/0cdf39d48fb171bf1e450b6d82689267
     */
    Masked.prototype.extend = function (out) {
        var out = out || {};
        for (var i = 1; i < arguments.length; i++) {
            if (!arguments[i])
                continue;

            for (var key in arguments[i]) {
                if (arguments[i].hasOwnProperty(key))
                    out[key] = arguments[i][key];
            }
        }
        return out;
    };


    Masked.prototype.validChar = function (re, char) {
        var r = new RegExp(re);
        return r.test(char) ? char : '';
    };

    /**
     * Add events
     */
    Masked.prototype.addEvents = function () {
        var that = this;
        this.el.onkeypress = function (ev) {
            var char = ev.key || String.fromCharCode(ev.charCode);
            var str = ev.target.value + char;
            var pos = str.length;
            if (
                that.options.rule.test(char) &&
                pos <= that.options.template.length
            ) {

                if (that.options.template.charAt(pos - 1) !== " ") {
                    str = ev.target.value + that.options.template.charAt(pos - 1) + char
                }

                ev.target.value = str;
            }

            return false;
        };
    };

    /**
     * Initiate
     */
    Masked.prototype.init = function () {
        this.options = this.extend(this.defaults, this.options);
        this.addEvents();
    };

    /////Auto Start
    var inputObjs = document.querySelectorAll('input[data-js-masked]');
    for (var index in inputObjs) {
        if (inputObjs.hasOwnProperty(index)) {
            var options = inputObjs[index].dataset.maskedOptions || null;
            var masked = new Masked(inputObjs[index], options);
            masked.init();
        }
    }

}(window, document);
